import React, {
    lazy,
    Suspense,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";

import {
    matchPath,
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation
} from "react-router-dom";
import userflow from "userflow.js";
import SideBar from "./SideBar";
import { Button, Modal, Result, Spin } from "antd";
import {
    isEnvironmentContentCreationOnly,
    isEnvironmentFreeResourcesOnly,
    isUserAdmin,
    isUserSiteAdmin,
    paymentModalVisible,
    useLoginState,
    userHasMockPapersAccess
} from "helpers/security";
import Tour, {
    sectionStarts,
    TourContext,
    tourDelays
} from "features/tour/Tour";
import { TwoWeekContextProvider } from "features/timetable/TwoWeekContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MobileIntercept from "commonComponents/MobileIntercept";
import ContentSiteRoutes from "app/ContentSiteRoutes";
import FreeResourcesSiteRoutes from "app/FreeResourcesSiteRoutes";
import { useGetUserQuery } from "features/account/accountSlice";
import { useQuery } from "helpers/hooks";
import { useMathJax } from "helpers/questionHelpers";

const LessonActivity = lazy(() => import("features/lesson/Lesson"));
const MockPapersActivity = lazy(
    () => import("features/mockPapers/MockPapersActivity")
);
const AdminDashboard = lazy(() => import("features/admin/AdminDashboard"));
const PublicHomepage = lazy(() => import("pages/PublicHomepage"));
const AccountPage = lazy(() => import("features/settings/AccountPage"));
const Dashboard = lazy(() => import("features/dashboard/Dashboard"));
const MultiplicationActivity = lazy(
    () => import("features/quickTables/MultiplicationActivity")
);
export const SIGN_UP_PATH = "/sign-up";
export const MOCK_PAPERS_PATH = "/classroom-mocks";
const SIGN_UP_SINGLE_PATH = `${SIGN_UP_PATH}/single`;
const PUBLIC_PATHS = [
    { path: SIGN_UP_PATH, exact: true },
    { path: SIGN_UP_SINGLE_PATH, exact: true }
];

userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);

const ExternalRedirect = ({ to }) => {
    useEffect(() => {
        if (!window?.location) {
            return;
        }
        window.location.replace(to);
    }, [to]);

    return null;
};

function App() {
    const location = useLocation();
    const history = useHistory();

    useMathJax();

    const [hideSideBar, setHideSideBar] = useState(false);

    const isContentEnvironment = isEnvironmentContentCreationOnly();
    const isFreeResourcesEnvironment = isEnvironmentFreeResourcesOnly();
    const skipAuthentication = Boolean(
        isFreeResourcesEnvironment ||
            (!isContentEnvironment &&
                PUBLIC_PATHS.some((props) =>
                    matchPath(location.pathname, props)
                ))
    );
    const isLoggedIn = useLoginState();
    const { data: user } = useGetUserQuery(undefined, {
        skip: skipAuthentication
    });
    const isAuthenticated = Boolean(user);
    const userflowAlreadyIdentified = useRef(false);

    const [fullscreen, setFullscreen] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [tourModalCloseFunctions, setTourModalCloseFunctions] = useState([]);
    const addTourModalClose = (name, fn) =>
        setTourModalCloseFunctions((prev) => {
            const copy = [...prev];
            copy.push({ name: name, method: fn });
            return copy;
        });
    const containsModalClose = (name) =>
        tourModalCloseFunctions.filter((fn) => fn.name === name).length > 0;
    const [currentTourStep, setCurrentTourStep] = useState(0);

    const clearApplicationStateForTour = useCallback(() => {
        if (fullscreen) {
            makeFullscreen(false);
        }
        Modal.destroyAll();
        tourModalCloseFunctions.forEach((fn) => fn.method());
    }, [fullscreen, tourModalCloseFunctions]);

    const setTourStepWithDelay = (n) =>
        setTimeout(() => setCurrentTourStep(n), tourDelays[n]);

    const nextTourStepWithDelay = () =>
        setTimeout(
            /* Doesn't use lambda function inside setCurrentTourStep to try and prevent skipped steps */
            /* Hopefully won't cause other issues/race conditions */
            () => setCurrentTourStep(currentTourStep + 1),
            tourDelays[currentTourStep + 1]
        );

    const prevTourStepWithDelay = () =>
        setTimeout(
            /* Doesn't use lambda function inside setCurrentTourStep to try and prevent skipped steps */
            /* Hopefully won't cause other issues/race conditions */
            () => setCurrentTourStep(currentTourStep - 1),
            tourDelays[currentTourStep - 1]
        );

    const setTourSectionWithDelay = (n) => {
        setTimeout(
            () =>
                setCurrentTourStep(() => {
                    const newSection = sectionStarts[n];
                    clearApplicationStateForTour();
                    return newSection.index;
                }),
            tourDelays[sectionStarts[n].index]
        );
    };

    const startTour = useCallback(() => {
        history.push("/dashboard");
        clearApplicationStateForTour();
    }, [history, clearApplicationStateForTour]);

    const endTour = () => setIsTourOpen(false);

    useEffect(() => {
        if (!user || userflowAlreadyIdentified?.current) {
            return;
        }
        userflow.identify(
            user["custom:appUserId"],
            {
                name: `${user.given_name} ${user.family_name}`,
                email: user.email,
                school: user?.["custom:school"] || null,
                signed_up_at: user?.created_time,
                has_mock_papers: userHasMockPapersAccess(user)
            },
            {
                signature: user?.userflow_signature
            }
        );
        userflowAlreadyIdentified.current = true;
    }, [user]);

    // Insert hotjar script if REACT_APP_HOTJAR_ID is present (i.e. only in specified environments)
    useEffect(() => {
        ((h, o, t, j, a, r) => {
            if (!process.env?.REACT_APP_HOTJAR_ID) {
                return;
            }
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: 6 };
            h._scriptPath = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            if (
                !document.querySelector('script[src*="' + h._scriptPath + '"]')
            ) {
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = h._scriptPath;
                a.appendChild(r);
            }
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }, []);

    function makeFullscreen(enterFullscreen) {
        const fullscreenEnabled =
            document.fullscreenEnabled ||
            document.webkitFullscreenEnabled /* Safari */ ||
            document.msFullscreenEnabled; /* IE11 */

        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement /* Safari */ ||
            document.msFullscreenElement; /* IE11 */

        if (enterFullscreen && fullscreenEnabled && !fullscreenElement) {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen()
                    .then(() => {
                        setFullscreen(true);
                        setHideSideBar(true);
                    })
                    .catch(() => {});
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
                setFullscreen(true);
                setHideSideBar(true);
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
                setFullscreen(true);
                setHideSideBar(true);
            }
        } else if (!enterFullscreen && fullscreenEnabled && fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
            setFullscreen(false);
        }
    }

    function handleFullscreenChange() {
        const fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement /* Safari */ ||
            document.msFullscreenElement; /* IE11 */

        if (!fullscreenElement) {
            // if exited fullscreen
            setHideSideBar(false);
            setFullscreen(false);
        } else {
            setFullscreen(true);
        }
    }

    useEffect(() => {
        document.addEventListener(
            "webkitfullscreenchange",
            handleFullscreenChange,
            false
        );
        document.addEventListener(
            "mozfullscreenchange",
            handleFullscreenChange,
            false
        );
        document.addEventListener(
            "msfullscreenchange",
            handleFullscreenChange,
            false
        );
        document.addEventListener(
            "MSFullscreenChange",
            handleFullscreenChange,
            false
        );
        document.addEventListener(
            "fullscreenchange",
            handleFullscreenChange,
            false
        );
        document.addEventListener("keydown", (e) => {
            if (e.which === 122 /*F11*/) {
                e.preventDefault();
                makeFullscreen(!fullscreen);
            }
        });
    });

    const query = useQuery();

    const admin = isUserAdmin(user);
    const isSiteAdmin = isUserSiteAdmin(user);

    const dashboardProps = {
        startTour,
        setTourSectionWithDelay
    };

    const standardRoutes = (
        <Switch>
            {admin && (
                <Route path="/admin">
                    <Helmet>
                        <title>MathsPlanner - Admin Dashboard</title>
                    </Helmet>
                    {isAuthenticated && <AdminDashboard />}
                </Route>
            )}
            <Route path={`/dashboard/tour`}>
                <Helmet>
                    <title>MathsPlanner - Dashboard</title>
                </Helmet>
                {isAuthenticated && (
                    <Dashboard
                        {...dashboardProps}
                        startTourInitially={(query.get("step") || 1) - 1}
                    />
                )}
            </Route>
            <Route path="/dashboard">
                <Helmet>
                    <title>MathsPlanner - Dashboard</title>
                </Helmet>
                {isAuthenticated && <Dashboard {...dashboardProps} />}
            </Route>
            <Route path={`/starter/gcse`}>
                <Helmet>
                    <title>MathsPlanner - GCSE Starter</title>
                </Helmet>
                {isAuthenticated && (
                    <LessonActivity
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                        loadId={query.get("id")}
                        key={query.get("key")}
                        gcseStarterParams={{
                            grades: query.get("grades"),
                            topics: query.get("topics")
                        }}
                        autoStarterParams={null}
                    />
                )}
            </Route>
            <Route path={`/starter/auto`}>
                <Helmet>
                    <title>MathsPlanner - Auto-generated Lesson Starter</title>
                </Helmet>
                {isAuthenticated && (
                    <LessonActivity
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                        loadId={query.get("id")}
                        key={query.get("key")}
                        gcseStarterParams={null}
                        autoStarterParams={{
                            num: query.get("num"),
                            topics: query.get("topics")
                        }}
                    />
                )}
            </Route>
            <Route path="/starter">
                <Helmet>
                    <title>MathsPlanner - Lesson Starter</title>
                </Helmet>
                {isAuthenticated && (
                    <LessonActivity
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                        loadId={query.get("id")}
                        gcseStarterParams={null}
                        autoStarterParams={null}
                    />
                )}
            </Route>
            <Route path={`${MOCK_PAPERS_PATH}/:id?`}>
                <Helmet>
                    <title>MathsPlanner - Mock Papers</title>
                </Helmet>
                {isAuthenticated && (
                    <MockPapersActivity
                        fullscreen={fullscreen}
                        makeFullscreen={makeFullscreen}
                    />
                )}
            </Route>
            <Route path="/multiplication">
                <Helmet>
                    <title>MathsPlanner - Quick Tables</title>
                </Helmet>
                {isAuthenticated && (
                    <div className="wrap grey">
                        <div
                            className={
                                "appMultiplicationWrapper " +
                                (fullscreen ? "fullscreen" : "")
                            }
                        >
                            <MultiplicationActivity
                                fullscreen={fullscreen}
                                makeFullscreen={makeFullscreen}
                            />
                        </div>
                    </div>
                )}
            </Route>
            <Route path="/settings/:pageKey">
                <Helmet>
                    <title>MathsPlanner - Settings</title>
                </Helmet>
                {isAuthenticated && <AccountPage />}
            </Route>
            {(isLoggedIn || isAuthenticated) && (
                <Redirect from="/" exact to="/dashboard" />
            )}
            {isAuthenticated && (
                <Redirect from="/start-trial" exact to="/dashboard" />
            )}
            <Route path={SIGN_UP_SINGLE_PATH} exact>
                <ExternalRedirect to="https://www.mathsplanner.com/request-free-trial/" />
            </Route>
            <Route path={SIGN_UP_PATH} exact>
                <ExternalRedirect to="https://www.mathsplanner.com/request-free-trial/" />
            </Route>
            <Route path="/" exact>
                <PublicHomepage />
            </Route>
            <Route>
                <Result
                    className={"warning404"}
                    status="404"
                    title={"404: This page cannot be found"}
                    subTitle={
                        <>
                            If you clicked a link to get here please report this
                            to:{" "}
                            <a href={"mailto:chloe@mathsplanner.com"}>
                                chloe@mathsplanner.com
                            </a>
                        </>
                    }
                    extra={
                        <Button type={"primary"} href={"/"}>
                            Back to Home Page
                        </Button>
                    }
                />
            </Route>
            {!isAuthenticated && <Redirect from="/" to="/" />}
        </Switch>
    );

    return (
        <HelmetProvider>
            <TwoWeekContextProvider initWeekBSelected={user?.week_b}>
                <TourContext.Provider
                    value={{
                        isOpen: isTourOpen && !paymentModalVisible(user),
                        currentStep: currentTourStep,
                        nextStep: nextTourStepWithDelay,
                        setStep: setTourStepWithDelay,
                        startTour: startTour,
                        endTour: endTour,
                        addTourModalClose: addTourModalClose,
                        containsModalClose: containsModalClose
                    }}
                >
                    <Tour
                        isOpen={isTourOpen && !paymentModalVisible(user)}
                        closeTour={endTour}
                        curr={currentTourStep}
                        nextStep={nextTourStepWithDelay}
                        prevStep={prevTourStepWithDelay}
                        userIsSiteAdmin={isSiteAdmin}
                        setSection={setTourSectionWithDelay}
                        isFullscreen={fullscreen}
                    />
                    <div
                        id="app"
                        className={
                            "app-container " + (isTourOpen && "tourOpen")
                        }
                    >
                        <Helmet>
                            <meta
                                name="description"
                                content="MathsPlanner - Application for maths teaching resources."
                            />
                            <title>
                                MathsPlanner - Application for maths teaching
                                resources
                            </title>
                            <meta
                                name="keywords"
                                content="Maths, MathsPlanner, Maths Questions, Maths Lessons, GCSE, Lesson Planning, Higher, Foundation, Answers, Maths Questions with Answers"
                            />
                        </Helmet>
                        {/* Mobile intercept */}
                        <Switch>
                            {/* Allow these routes on mobile */}
                            <Route path={"/"} exact />
                            <Route path={SIGN_UP_PATH} />
                            <Route path={SIGN_UP_SINGLE_PATH} />
                            {/* Hide all other routes on mobile */}
                            <Route>
                                {isFreeResourcesEnvironment ? (
                                    <MobileIntercept
                                        name={"Quick Tables"}
                                        text={
                                            "To ensure you have the best experience, we'll send you an email to this " +
                                            "resource so you can check it out once you're back at your computer!."
                                        }
                                        videoTitle={
                                            "Watch Quick Tables in action"
                                        }
                                        embedID={"mIhceUGNvjA"}
                                        showForm
                                    />
                                ) : (
                                    <MobileIntercept
                                        name={"Maths Planner"}
                                        text={
                                            "To ensure you have the best experience, please login once you’re back " +
                                            "at your computer."
                                        }
                                        videoTitle={
                                            "How-to create your first starter"
                                        }
                                        embedID={"yDH4WWqo8_M"}
                                        showEmails
                                    />
                                )}
                            </Route>
                        </Switch>
                        {/* Sidebar */}
                        <Switch>
                            {/* Don't render sidebar for these routes */}
                            {!isFreeResourcesEnvironment && (
                                <Route path={"/"} exact />
                            )}
                            {!isFreeResourcesEnvironment && (
                                <Route path={SIGN_UP_PATH} />
                            )}
                            {!isFreeResourcesEnvironment && (
                                <Route path={SIGN_UP_SINGLE_PATH} />
                            )}
                            {!isFreeResourcesEnvironment && (
                                <Route path={"/starter"}>
                                    {!hideSideBar && (
                                        <SideBar sideEffectsOnly />
                                    )}
                                </Route>
                            )}
                            {/* Render sidebar for all other routes */}
                            <Route>{!hideSideBar && <SideBar />}</Route>
                        </Switch>
                        <Suspense
                            fallback={
                                <div className="loadingContainer">
                                    <Spin tip="Loading..." />
                                </div>
                            }
                        >
                            {isContentEnvironment ? (
                                <ContentSiteRoutes
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                />
                            ) : isFreeResourcesEnvironment ? (
                                <FreeResourcesSiteRoutes
                                    fullscreen={fullscreen}
                                    makeFullscreen={makeFullscreen}
                                />
                            ) : (
                                standardRoutes
                            )}
                        </Suspense>
                    </div>
                </TourContext.Provider>
            </TwoWeekContextProvider>
        </HelmetProvider>
    );
}

export default App;
